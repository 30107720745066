import React from 'react';
import PropType from 'prop-types';
import {HashLink} from 'react-router-hash-link';

const ResourceCard = props => {
  const {title, content, image, className, path} = props;

  return <div
    className={`cell shadow resource-card ${className}`}>
    <HashLink
      className='card has-background-image height-100'
      scroll={() => null}
      to={path}>
      <div className='card-section'>
        <p><img
          alt={title}
          src={image}
          width='160'/></p>
        <h3 className='font-bold'>{title}</h3>
        {content && <p>{content}</p>}
      </div>
      <div className='card-divider'>
        <button
          className='success shadow text-uppercase font-bold button'
          type='button'>EXPLORE NOW &#10132;</button>
      </div>
    </HashLink>
  </div>;
};

ResourceCard.propTypes = {
  className: PropType.string,
  content: PropType.string,
  image: PropType.string.isRequired,
  path: PropType.string.isRequired,
  title: PropType.string.isRequired
};

export default ResourceCard;
