import {combineReducers} from 'redux';
import {createRouterReducer} from '@lagunovsky/redux-react-router';
import selectedAnswers from './selectedAnswers';
import topics from './topics';
import sitePartners from './sitePartners';
import score from './score';
import preferences from './preferences';

const createRootReducer = history => combineReducers({
  router: createRouterReducer(history),
  selectedAnswers,
  topics,
  sitePartners,
  score,
  preferences
});

export default createRootReducer;
