import {SITE_PARTNERS} from '../constants';

export const getSitePartners = () => ({
  type: SITE_PARTNERS.GET_SITE_PARTNERS,
  payload: {
    request: {
      url: '/api/site-partners',
      method: 'GET'
    }
  }
});
