import React, {useEffect, useState} from 'react';
import {getPrivacyPolicy} from '../../../redux/actions/articles';
import {useDispatch} from 'react-redux';
import ApiError from '../ApiError';
import Spinner from '../Utils/Spinner';
import Showdown from 'showdown';
import MetaHemlet from '../../components/MetaHemlet';

const Index = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [privacyPolicyData, setPrivacyPolicyData] = useState(null);
  const converter = new Showdown.Converter();

  useEffect(() => {
    dispatch(getPrivacyPolicy())
      .then(action => {
        if (action.error) {
          setError(action.error);
        }
        else {
          setPrivacyPolicyData(action.payload.data.data.attributes);
        }
      });
  }, [dispatch]);

  const privacyPolicyContent = () => <>
    <div>
      {/* eslint-disable-next-line */}
        <div dangerouslySetInnerHTML={{__html: converter.makeHtml(privacyPolicyData.content)}}/>
    </div>
  </>;

  if (error) {
    return <ApiError error={error}/>;
  }
  else if (privacyPolicyData === null) {
    return <header><Spinner/></header>;
  }

  return (
    <>
      <MetaHemlet
        description='We respect your privacy. For this reason, we do not collect your personal data other
         than what allows you to use this app site.'
        title='Privacy Policy'
      />
      <header>
        <div className='h1'>Privacy Policy</div>
      </header>
      <section>
        <div className='grid-container'>
          <h1>Privacy Policy</h1>
          <div>
            {privacyPolicyContent() === null ? 'Not available at this moment please try again later'
              : privacyPolicyContent()}
          </div>
        </div>
      </section>
    </>);
};

export default Index;
