import React, {useEffect, useRef, useState} from 'react';
import {generatePath, Link, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import NoRoute from '../404';
import {getScreenerByURL} from '../../../redux/actions/screeners';
import {clearScore} from '../../../redux/actions/score';
import Spinner from '../Utils/Spinner';
import Showdown from 'showdown';
import ApiError from '../ApiError';
import ROUTES from '../../../routes';
import EmptyMessage from '../Utils/EmptyMessage';

const Results = () => {
  const {url} = useParams();
  const dispatch = useDispatch();
  const converter = new Showdown.Converter();
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [screener, setScreener] = useState(null);
  const score = useSelector(state => state.score);
  const componentMounted = useRef(false);

  useEffect(() => {
    dispatch(getScreenerByURL(url))
      .then(action => {
        if (action.error) {
          setError(action.error);
        }
        else {
          setScreener(action.payload.data);
        }
      });
  }, [dispatch, url]);

  useEffect(() => {
    dispatch(getScreenerByURL(url))
      .then(action => action.error && setError(action.error))
      .catch(err => setError(err));

    return () => {
      /*
       * react 18 renders component twice when using <React.StrictMode>
       * only dispath clearScore() if component was already mounted
       */
      if (componentMounted.current) {
        dispatch(clearScore());
      }

      componentMounted.current = true;
    };
  }, [url, dispatch]);

  if (error) {
    return <NoRoute error={error}/>;
  }

  if (screener === null) {
    return <header><Spinner/></header>;
  }

  const scorePercentage = `${score / screener.total_score * 100}%`;

  const screenerContent = () => {
    if (score === '' || score === null) {
      return (
        <div>
          <div>
            <EmptyMessage message='Screener results not available or expired.'/>
          </div>
          <p>
            Please re-take the screener to update your results.
          </p>
          <p>
            <Link
              className='success shadow text-uppercase font-bold button'
              to={generatePath(ROUTES.SCREENER_DETAILS_INTRO, {url: screener.url})}>
              Go To Screener &#10132;
            </Link>
          </p>
        </div>
      );
    }

    return (
      <>
        <div className='callout'>
          <div className='margin-horizontal-1'>
            <div
              className='text-center tooltip white-color radius text-uppercase'
              style={{'--left': scorePercentage}}>
                  Your<br/>Placement
            </div>
          </div>
          <div className='rounded gradient position-relative overflow-hidden'>
            <div className='position-relative margin-horizontal-1 height-100'>
              <div
                className='gradient-placement'
                style={{'--left': scorePercentage}}/>
            </div>
          </div>
          <div className='grid-x grid-margin-x align-middle'>
            <div className='auto cell text-center'>
              <p>Low</p>
            </div>
            <div className='text-center cell small-7 medium-9'>
              Level Indicator
            </div>
            <div className='auto cell text-center'>
              <p>High</p>
            </div>
          </div>
        </div>
        <div>
          <h1>Results</h1>
        </div>
        <p>Score: {score}/{screener.total_score}</p>
        {
          screener.result && <div>
            {/* eslint-disable-next-line */}
            <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(screener.result) }} />
          </div>
        }
      </>
    );
  };

  if (error) {
    return <ApiError error={error}/>;
  }
  else if (!screener) {
    return <header><Spinner/></header>;
  }

  return (
    <>
      <header>
        <div className='h1'>{score ? screener.title : 'No'} Results</div>
      </header>
      <section className='screener padding-0'>
        <div className='grid-container show-for-medium'>
          <div className='grid-x grid-padding-x align-stretch align-center'>
            <div className={`cell medium-8 ${screener.sidebar ? 'large-6' : 'large-8'} padding-1`}>
              {screenerContent()}
            </div>
            {
              screener.sidebar && <div className='cell medium-4 large-3 padding-1 sidebar'>
                {/* eslint-disable-next-line */}
                  <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(screener.sidebar) }} />
              </div>
            }
          </div>
        </div>
        <div className='grid-container hide-for-medium'>
          <ul className='tabs'>
            <li className={`tabs-title ${activeTab === 0 ? 'is-active' : ''}`}>
              <button
                className='primary-color primary padding-1'
                onClick={() => setActiveTab(0)}
                type='button'
              >RESULT</button>
            </li>
            {
              screener.sidebar && <li className={`tabs-title ${activeTab === 1 ? 'is-active' : ''}`}>
                <button
                  className='primary-color primary padding-1'
                  onClick={() => setActiveTab(1)}
                  type='button'
                >OTHER RECOMMENDATIONS</button>
              </li>
            }
          </ul>
          <div className='tabs-content'>
            <div className={`tabs-panel  ${activeTab === 0 ? 'is-active' : ''}`}>
              <div className='cell medium-6 large-6'>
                {screenerContent()}
              </div>
            </div>
            {
              screener.sidebar &&
                <div className={`tabs-panel ${activeTab === 1 ? 'is-active' : ''}`}>
                  {/* eslint-disable-next-line */}
                  <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(screener.sidebar) }} />
                </div>
            }
          </div>
        </div>
      </section>
    </>
  );
};

export default Results;
