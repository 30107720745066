import {TOPICS} from '../constants';
import _ from 'lodash';

const initialState = null;

export default function topics(state = initialState, action) {
  switch (action.type) {
  case TOPICS.GET_TOPICS_SUCCESS: {
    return _.sortBy(action.payload.data, 'index');
  }

  default:
    return state;
  }
}
