import React, {useState, useEffect} from 'react';
import {getHomePageVideos} from '../../../redux/actions/homePageVideos';
import {useDispatch} from 'react-redux';
import _ from 'lodash';
import Modal from '../../components/Modal';

const Index = () => {
  const dispatch = useDispatch();
  const [videos, setVideos] = useState(null);
  // const [mainVideo, setMainVideo] = useState(null);
  const [error, setError] = useState(null);
  const [modal, setModal] = useState(false);

  const handleVideos = unsortedVideos => {
    if (unsortedVideos && unsortedVideos.length > 0) {
      const sortedVideos = _.sortBy(unsortedVideos, 'index');

      if (sortedVideos.length > 0) {
        setVideos(sortedVideos);
      }
    }
  };

  const showVideo = video => <>
    <iframe
      allow='autoplay; fullscreen'
      allowFullScreen
      className='align-center width-100'
      frameBorder='0'
      src={video.videoLink}
      title={video.title}
      width='600'/>
  </>;

  const showVideoCaption = video => <>
    <div className='text-uppercase'>{video.title}</div>
    {video.caption && <div className='h1 font-bold margin-0'>{video.caption}</div>}
    {
      videos.length > 1
        ? <div>
          <button
            className='button info hollow rounded teal-color margin-vertical-1'
            onClick={() => setModal(true)}
            type='button'>
          Watch Related Videos
          </button>
        </div>
        : null
    }
  </>;

  useEffect(() => {
    dispatch(getHomePageVideos())
      .then(action => {
        if (action.error) {
          setError(action.error);
        }
        else {
          handleVideos(action.payload.data);
        }
      });
  }, [dispatch]);

  // console.log(!error, videos !== null, videos.length > 0, mainVideo);
  if (!error && videos !== null && videos.length > 0) {
    return (
      <section className='success-bg-color'>
        <div className='grid-container'>
          <div className='grid-x align-center-middle grid-margin-y grid-margin-x'>
            <div
              className='cell medium-7 padding-right-1'>
              <iframe
                allow='autoplay; fullscreen'
                allowFullScreen
                className='align-center width-100 home-page-video'
                frameBorder='0'
                src={videos[0].videoLink}
                title={videos[0].title}/>
            </div>
            <div className='cell medium-4 align-center teal-color text-center medium-text-left'>
              {showVideoCaption(videos[0])}
            </div>
          </div>
          <Modal
            onClose={() => {
              setModal(false);
            }}
            show={modal}>
            <div className='reveal padding-2'>
              <button
                className='close-button'
                onClick={() => setModal(false)}
                type='button'>
                <span aria-hidden='true'>&times;</span>
              </button>
              {
                videos.length > 1 &&
              <div className='grid-x grid-margin-x grid-margin-y medium-up-3'>
                {
                  videos.map((video, index) => index === 0
                    ? null
                    : <div
                      className='cell'
                      /* eslint-disable-next-line react/no-array-index-key */
                      key={index}>
                      {showVideo(video)}
                    </div>)
                }
              </div>
              }
            </div>
          </Modal>
        </div>
      </section>
    );
  }

  return null;
};

export default Index;
