import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import ImageNotAvailable from '../../assets/images/image-not-available.jpeg';

// The formats below must be arranged from largest to smallest in order to be properly applied
const FORMATS = ['large', 'medium', 'small', 'thumbnail'];

const NetworkImage = ({className, image, format, height, width, children, header, imageCaption}) => {
  const [imageUrl, updateImageUrl] = useState('');

  const fetchImage = url => fetch(url, {method: 'HEAD', cache: 'no-cache'})
    .then(data => {
      if (data.status === 200) {
        return data.url;
      }

      return ImageNotAvailable;
    }).catch(() => ImageNotAvailable);

  useEffect(() => {
    const getSrc = () => {
      if (!image || !image.formats) {
        return ImageNotAvailable;
      }

      if (!FORMATS.includes(format) || !format) {
        return fetchImage(image.formats.thumbnail.url);
      }

      for (let i = FORMATS.indexOf(format); i < FORMATS.length; i++) {
        if (image.formats[`${FORMATS[i]}`]) {
          return fetchImage(image.formats[`${FORMATS[i]}`].url);
        }
      }

      return ImageNotAvailable;
    };

    const updateImageUrlFunc = async() => {
      updateImageUrl(await getSrc());
    };

    updateImageUrlFunc();
  }, [image, format]);

  if (header) {
    return <header
      className={`${className} background-image`.trim()}
      style={{backgroundImage: `url("${imageUrl}")`}}>
      {children}
    </header>;
  }

  return children
    ? <div
      className={`${className} background-image`.trim()}
      style={{backgroundImage: `url("${imageUrl}")`}}>
      {children}
    </div>
    : <figure>
      <img
        alt={image ? image.alternativeText : ''}
        className={className}
        height={height}
        src={imageUrl}
        width={width}/>
      {image && imageCaption && image.caption && <figcaption className='font-italic text-truncate text-lowercase'>
        {image.caption}
      </figcaption>}
    </figure>;
};

NetworkImage.defaultProps = {
  imageCaption: true
};

NetworkImage.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  format: PropTypes.oneOf(FORMATS),
  header: PropTypes.bool,
  height: PropTypes.number,
  image: PropTypes.shape({
    alternativeText: PropTypes.string,
    caption: PropTypes.string,
    formats: PropTypes.shape({
      thumbnail: PropTypes.shape({
        url: PropTypes.string
      }),
      small: PropTypes.shape({
        url: PropTypes.string
      }),
      medium: PropTypes.shape({
        url: PropTypes.string
      }),
      large: PropTypes.shape({
        url: PropTypes.string
      })
    })
  }),
  imageCaption: PropTypes.bool,
  width: PropTypes.number
};

export default NetworkImage;
