import {SCORE} from '../constants';

export const setScore = score => ({
  type: SCORE.SET_SCORE,
  score
});

export const clearScore = () => ({
  type: SCORE.CLEAR_SCORE
});
