import {SITE_PARTNERS} from '../constants';
import sortSitePartners from '../../../src/app/containers/Utils/sortSitePartners';

const initialState = [];

export default function sitePartners(state = initialState, action) {
  switch (action.type) {
  case SITE_PARTNERS.GET_SITE_PARTNERS_SUCCESS: {
    return sortSitePartners(action.payload.data);
  }

  default:
    return state;
  }
}
