import React from 'react';
import TopicBackgroundImage from '../../../assets/images/Hope3.0-TopicLanding-BG.jpg';
import PropType from 'prop-types';
import ResourceCard from '../Utils/ResourceCard';
import IconDiscover from '../../../assets/images/icons/icon-discover.png';
import IconLearn from '../../../assets/images/icons/icon-learn.png';
import IconPerspective from '../../../assets/images/icons/icon-perspective.png';

const Resources = props => {
  const {topic} = props;

  return <div id='resources'>
    <div className='text-center grid-container padding-vertical-3'>
      <div className='grid-x align-center'>
        <div className='cell medium-7'>
          <h2 className={topic.next_level_description ? '' : 'margin-0'}>{topic.title}</h2>
          {topic.next_level_description}
        </div>
      </div>
    </div>
    {
      topic.videoLink && <section>
        <div className='grid-x align-center-middle grid-margin-y grid-margin-x'>
          <div
            className='cell medium-7 padding-right-1'>
            <iframe
              allow='autoplay; fullscreen'
              allowFullScreen
              className='align-center width-100 home-page-video'
              frameBorder='0'
              src={topic.videoLink}
              title={topic.title}/>
          </div>
          <div className='cell medium-4 align-center teal-color text-center medium-text-left'>
            <div className='text-uppercase'>{topic.videoTitle}</div>
            {topic.videoCaption && <div className='h1 font-bold margin-0'>{topic.videoCaption}</div>}
          </div>
        </div>
      </section>
    }
    <section
      style={{backgroundImage: `url(${TopicBackgroundImage})`}}>
      <div className='grid-container'>
        <h3>HOPE offers</h3>
        {topic.resource_description &&
          <p>
            {topic.resource_description}
          </p>
        }

        <div className='grid-x medium-up-3 align-center grid-margin-x grid-margin-y'>
          <ResourceCard
            className='screeners'
            content={topic.screener_resource_description}
            image={IconDiscover}
            path='#screeners'
            title='Is This Me?'/>
          <ResourceCard
            className='courses'
            content={topic.course_resource_description}
            image={IconLearn}
            path='#courses'
            title='What Can I Do?'/>
          <ResourceCard
            className='articles'
            content={topic.article_resource_description}
            image={IconPerspective}
            path='#articles'
            title='Can You Tell Me More?'/>
        </div>
      </div>
    </section>
  </div>;
};

Resources.propTypes = {
  topic: PropType.object.isRequired
};

export default Resources;
