import {COURSES} from '../constants';

export const getCourseByURL = url => ({
  type: COURSES.GET_COURSE,
  payload: {
    request: {
      url: `/api/courses/${url}`,
      method: 'GET'
    }
  }
});
