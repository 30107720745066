import {TOPICS} from '../constants';

const initialState = {
  currentTopic: null
};

export default function preferences(state = initialState, action) {
  switch (action.type) {
  case TOPICS.GET_TOPIC_SUCCESS: {
    return {...state, currentTopic: action.payload.data};
  }

  default:
    return state;
  }
}
