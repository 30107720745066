import React, {useEffect, useRef, useState} from 'react';
import ReliefAreaCards from './ReliefAreaCards';
import Videos from '../Videos';
import CarouselGrief from '../../../assets/images/carousel/carousel-grief.jpg';
import CarouselMomBaby from '../../../assets/images/carousel/carousel-mom-baby.jpg';
import CarouselPregnancy from '../../../assets/images/carousel/carousel-pregnancy.jpg';
import CarouselSenior from '../../../assets/images/carousel/carousel-senior.jpg';
import IconDiscover from '../../../assets/images/icons/icon-discover.png';
import IconLearn from '../../../assets/images/icons/icon-learn.png';
import IconPerspective from '../../../assets/images/icons/icon-perspective.png';
import UrgentResourceBackgroundImage from '../../../assets/images/flower.jpg';
import NetworkImage from '../../components/NetworkImage';
import {useDispatch, useSelector} from 'react-redux';
import {getTopics} from '../../../redux/actions/topics';
import {getSitePartners} from '../../../redux/actions/sitePartners';
import {Link} from 'react-router-dom';
import ROUTES from '../../../routes';
const CAROUSEL_PHOTOS = [CarouselMomBaby, CarouselPregnancy, CarouselGrief, CarouselSenior];

const Index = () => {
  const dispatch = useDispatch();
  const carouselTimer = useRef();
  const [carouselPhotoIndex, setCarouselPhotoIndex] = useState(0);
  const topics = useSelector(state => state.topics);
  const sitePartners = useSelector(state => state.sitePartners);

  useEffect(() => {
    dispatch(getTopics());
    dispatch(getSitePartners());
  }, [dispatch]);

  useEffect(() => {
    const changePhoto = () => {
      setCarouselPhotoIndex((carouselPhotoIndex + 1) % CAROUSEL_PHOTOS.length);
    };

    carouselTimer.current = setTimeout(changePhoto, 5000);

    return () => {
      clearTimeout(carouselTimer.current);
    };
  }, [carouselPhotoIndex]);

  return <>
    <header
      className='black-color'
      style={{backgroundImage: `url(${CAROUSEL_PHOTOS[carouselPhotoIndex]})`}}>
      <div className='grid-container width-100 text-left'>
        <p className='text-uppercase dark-gray-color'>Welcome to Hope</p>
        <p className='h1 margin-bottom-1 teal-color'>If you&apos;re feeling anxious<br/>
          or overwhelmed, you&apos;re<br/>in the right place.</p>
        <p className='lead dark-gray-color font-bold'>Begin your path toward better
          <br/>emotional health, no matter<br/>your starting point.</p>
        <a
          className='success shadow text-uppercase font-bold button margin-top-2 margin-bottom-0'
          href='#topics'
          type='button'>Discover How</a>
      </div>
    </header>
    <Videos/>
    <section
      className='dark'
      id='topics'>
      <div className='grid-container'>
        <div className='grid-x align-center'>
          <div className={`${topics % 2 === 0 ? 'large-8' : 'large-12'} cell`}>
            <h2 className='text-center padding-bottom-2'>Select a life experience to explore</h2>
            {topics && <ReliefAreaCards topics={topics}/>}
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className='grid-container text-center'>
        <h2 className='text-center'><strong>The Hope Experience</strong></h2>
        <div className='grid-x grid-margin-x grid-margin-y medium-up-2 large-up-3'>
          <div className='cell'>
            <p><img
              alt='notepad'
              src={IconDiscover}/></p>
            <h3 className='font-bold'>Is This Me?</h3>
            <p>Assess your current mental health with science-based screeners</p>
          </div>
          <div className='cell'>
            <p><img
              alt='graduation cap'
              src={IconLearn}/></p>
            <h3 className='font-bold'>What Can I Do?</h3>
            <p>Support your emotional wellbeing with your choice of online therapies</p>
          </div>
          <div className='cell'>
            <p><img
              alt='book'
              src={IconPerspective}/></p>
            <h3 className='font-bold'>Can You Tell Me More?</h3>
            <p>Choose from bite-sized features on a variety of mental health topics</p>
          </div>
        </div>
      </div>
    </section>
    <section
      className='white-color'
      style={{backgroundImage: `url(${UrgentResourceBackgroundImage})`}}>
      <div className='grid-container width-100 text-left'>
        <div className='grid-x align-center-middle'>
          <div className='cell shrink medium-10 text-right'>
            <Link
              className='success text-uppercase font-bold margin-0 hollow button large'
              to={ROUTES.ABOUT}>About Hope</Link>
          </div>
        </div>
      </div>
    </section>
    {sitePartners &&
      <section
        className='white-background-color gradient-bg'
        id='site-partners'>
        <div className='grid-container'>
          <h3 className='text-center padding-bottom-2'>
          Your access to The Hope Platform has been sponsored by our partner organizations.
          </h3>
          <div
            className='grid-x grid-margin-x grid-margin-y align-center-middle small-up-2 medium-up-3 large-up-4'>
            <>
              {sitePartners.map(sitePartner =>
                <div
                  className='cell text-center'
                  key={sitePartner.id}>
                  <NetworkImage
                    format='small'
                    image={sitePartner.image}
                    imageCaption={false}
                  />
                </div>)}
            </>
          </div>
        </div>
      </section>
    }
  </>;
};

export default Index;
