import React from 'react';
import {generatePath} from 'react-router-dom';
import Spinner from '../Utils/Spinner';
import EmptyMessage from '../Utils/EmptyMessage';
import ROUTES from '../../../routes';
import ImageCard from '../Utils/ImageCard';
import PropType from 'prop-types';
import ImageCardGroup from '../Utils/ImageCardGroup';
import IconDiscover from '../../../assets/images/icons/icon-discover.png';

const Screeners = ({topic}) => {
  const screeners = topic.screeners;

  const renderSingleScreener = screener => <div
    className='cell'
    key={screener.id}>
    <ImageCard
      action='Explore Now'
      description={screener.description}
      image={screener.image}
      path={generatePath(ROUTES.SCREENER_DETAILS_INTRO, {url: screener.url})}
      title={screener.title}/>
  </div>;

  const renderScreeners = () => {
    if (screeners === null) {
      return <header><Spinner/></header>;
    }
    else if (screeners.length === 0) {
      return <EmptyMessage
        alignCenter
        message='There are no screeners to show.'/>;
    }

    return <>
      {/* <ImageCardGroup className='show-for-medium'>{screeners.map((screener, index) =>*/}
      {/*  index < SETTINGS.NUM_SCREENERS_VISIBLE_BY_DEFAULT || allScreenersVisible*/}
      {/*    ? renderSingleScreener(screener)*/}
      {/*    : null)*/}
      {/* }</ImageCardGroup>*/}
      <ImageCardGroup className='large-up-3'>
        {screeners.map(screener => renderSingleScreener(screener))}
      </ImageCardGroup>
    </>;
  };

  return <div id='screeners'>
    <div className='grid-container'>
      <div className='grid-x align-center-middle padding-vertical-2 grid-margin-x grid-margin-y'>
        <div className='shrink cell auto'>
          <img
            alt='notepad'
            src={IconDiscover}/>
        </div>
        <div className='cell medium-5 text-center medium-text-left'>
          <h2>Is This Me?</h2>
          {topic.next_level_screener_resource_description}
        </div>
      </div>
      {renderScreeners()}
    </div>
    {/* {screeners &&*/}
    {/*  screeners.length > SETTINGS.NUM_SCREENERS_VISIBLE_BY_DEFAULT && !allScreenersVisible &&*/}
    {/*  <div*/}
    {/*    className='text-center show-for-medium'*/}
    {/*    onClick={() => toggleShowAllScreeners(!allScreenersVisible)}>*/}
    {/*    <a className='primary large font-bold button hollow margin-top-2 rounded text-uppercase'>*/}
    {/*      Load more screeners*/}
    {/*    </a>*/}
    {/*  </div>}*/}
  </div>;
};

Screeners.propTypes = {
  topic: PropType.object
};

export default Screeners;
