import {TOPICS} from '../constants';

export const getTopics = () => ({
  type: TOPICS.GET_TOPICS,
  payload: {
    request: {
      url: '/api/topics',
      method: 'GET'
    }
  }
});

export const getTopicByURL = url => ({
  type: TOPICS.GET_TOPIC,
  payload: {
    request: {
      url: `/api/topics/${url}`,
      method: 'GET'
    }
  }
});
