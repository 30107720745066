import React from 'react';
import PropTypes from 'prop-types';
import MetaHemlet from './MetaHemlet';

const RouteWrapper = function({component: Component, title, description}) {
  return (
    <>
      <MetaHemlet
        description={description}
        title={title}/>
      <Component/>
    </>
  );
};

export default RouteWrapper;

RouteWrapper.propTypes = {
  component: PropTypes.any,
  description: PropTypes.string,
  title: PropTypes.string
};

RouteWrapper.defaultProps = {
  component: null
};
