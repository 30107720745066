import React, {useEffect, useState} from 'react';
import {getDisclaimer} from '../../../redux/actions/articles';
import {useDispatch} from 'react-redux';
import ApiError from '../ApiError';
import Spinner from '../Utils/Spinner';
import Showdown from 'showdown';

const Index = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [disclaimerData, setdisclaimerData] = useState(null);
  const converter = new Showdown.Converter();

  useEffect(() => {
    dispatch(getDisclaimer())
      .then(action => {
        if (action.error) {
          setError(action.error);
        }
        else {
          setdisclaimerData(action.payload.data.data.attributes);
        }
      });
  }, [dispatch]);

  const disclaimerContent = () => <>
    {/* eslint-disable-next-line */}
    <div dangerouslySetInnerHTML={{__html: converter.makeHtml(disclaimerData.content)}}/>
  </>;

  if (error) {
    return <ApiError error={error}/>;
  }
  else if (disclaimerData === null) {
    return <header><Spinner/></header>;
  }
  else if (disclaimerContent() === null) {
    return <section><p/></section>;
  }

  return (
    <>
      <header>
        <div className='h1'>Disclaimer</div>
      </header>
      <section>
        <div className='grid-container'>
          <h1>Disclaimer</h1>
          <div>
            {disclaimerContent() === null ? 'Not available at this moment please try again later'
              : disclaimerContent()}
          </div>
        </div>
      </section>
    </>);
};

export default Index;
