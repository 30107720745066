/* eslint-disable no-undef */

import axios from 'axios';
const _ = require('lodash');
const mapDeep = require('deepdash/getMapDeep')(_);

const client = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  responseType: 'json'
});

const addUrlToImageFormatsIfMissing = object => {
  mapDeep(object, (_value, key, parent) => {
    if (key === 'formats') {
      // eslint-disable-next-line no-unused-vars
      for (const [_key, imageFormat] of Object.entries(parent.formats)) {
        if (imageFormat instanceof Object && imageFormat.hash && imageFormat.ext) {
          imageFormat.url ||= `${process.env.REACT_APP_AWS_S3_URL}/${imageFormat.hash}${imageFormat.ext}`;
        }
      }
    }
  });
};

export const axiosConfig = {
  interceptors: {
    response: [(action, request) => {
      /*
       * Unfortunately Strapi always returns relative paths for all media, unless a provider
       * is configured. This means that in local environment Strapi will return '/uploads/...'
       * instead of 'http://localhost:1337/uploads/...', which leaves the consumer with the task
       * of prefixing all URLs. However, when using AWS S3 or other providers in production,
       * Strapi will use absolute urls, thus requiring the developer to have to maintain
       * two inconsistent environments for managing assets.
       * This block will prepend the Strapi server URL to all media assets.
       */
      if (process.env.NODE_ENV === 'development' && request.data) {
        const relativeUploadsPath = '/uploads/';
        const absoluteUploadsPath = `${process.env.REACT_APP_API_BASE_URL}${relativeUploadsPath}`;
        const relativeUrlData = JSON.stringify(request.data);
        const absoluteUrlData = relativeUrlData.replaceAll(relativeUploadsPath, absoluteUploadsPath);
        request.data = JSON.parse(absoluteUrlData);
      }

      /*
       * Sometimes Strapi sends image formats as byte streams from the backend with no AWS S3 url in them.
       * Since frontend is unable to parse byte streams, the workaround is to generate the URLs manually.
       * URL generation is done by using the AWS S3 URL environment variable, the image hash, which comes
       * through the Strapi API call (which is also the name of the file in AWS S3), and appending the
       * image extension. The URL is only added when it does not already exist to keep the objects that
       * functions properly untouched. It is currently unknown why Strapi treats certain associations differently.
       */
      if (request.data) {
        addUrlToImageFormatsIfMissing(request.data);
      }

      return request;
    }]
  }
};

export default client;
