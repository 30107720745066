import React, {useEffect} from 'react';
import {Route, Routes, useLocation, useNavigationType} from 'react-router-dom';
import NoRoute from './app/containers/404';
import ROUTES from './routes';
import RouteWrapper from './app/components/RouteWrapper';
import PrivacyPolicy from './app/containers/PrivacyPolicy';
import Disclaimer from './app/containers/Disclaimer';
import Course from './app/containers/Course';
import Screener from './app/containers/Screener';
import Results from './app/containers/Screener/Results';
import EducationScreen from './app/containers/Topic';
import UrgentResourcesScreen from './app/containers/UrgentResources';
import Home from '../src/app/containers/Home/Index';
import Article from './app/containers/Article/index';
import About from './app/containers/About/index';
import Research from './app/containers/Research';

const ScrollToTop = () => {
  const location = useLocation();
  const navType = useNavigationType();

  useEffect(() => {
    if (navType !== 'POP') {
      window.scrollTo(0, 0);
    }
  }, [location, navType]);
  return null;
};

const App = function() {
  return <main id='main'>
    <ScrollToTop/>
    <Routes>
      <Route
        element={<RouteWrapper
          component={Home}
          title='Home'/>}
        path={ROUTES.ROOT}
      />
      <Route
        element={<RouteWrapper
          component={About}
          title='About'/>}
        path={ROUTES.ABOUT}
      />
      <Route
        element={<RouteWrapper
          component={EducationScreen}/>}
        path={ROUTES.TOPIC}
      />
      <Route
        element={<RouteWrapper
          component={UrgentResourcesScreen}/>}
        path={ROUTES.URGENT_RESOURCES}
      />
      <Route
        element={<RouteWrapper
          component={() => <div>Contact here</div>}
          title='Contact'/>}
        path={ROUTES.CONTACT}
      />
      <Route
        element={<RouteWrapper
          component={PrivacyPolicy}
          title='Privacy Policy'/>}
        path={ROUTES.PRIVACY_POLICY}
      />
      <Route
        element={<RouteWrapper
          component={Disclaimer}
          title='Disclaimer'/>}
        path={ROUTES.DISCLAIMER}
      />
      <Route
        element={<RouteWrapper
          component={Research}
          title='Research'/>}
        path={ROUTES.RESEARCH}
      />
      <Route
        element={<RouteWrapper
          component={Article}
          title='Article'/>}
        path={ROUTES.ARTICLE}
      />
      <Route
        element={<RouteWrapper
          component={Course}
          title='Course'/>}
        path={ROUTES.COURSE_DETAILS_INTRO}
      />
      <Route
        element={<RouteWrapper
          component={Course}
          title='Course'/>}
        path={ROUTES.COURSE_DETAILS}
      />
      <Route
        element={<RouteWrapper
          component={Results}
          title='Results'/>}
        path={ROUTES.SCREENER_RESULTS}
      />
      <Route
        element={<RouteWrapper
          component={Screener}
          title='Screener'/>}
        path={ROUTES.SCREENER_DETAILS_INTRO}
      />
      <Route
        element={<RouteWrapper
          component={Screener}
          title='Screener'/>}
        path={ROUTES.SCREENER_DETAILS}
      />
      <Route
        element={<RouteWrapper
          component={NoRoute}/>}
        path='*'
        title='404'
      />
    </Routes>
  </main>;
};

export default App;
