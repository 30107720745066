import React, {useState} from 'react';
import {generatePath} from 'react-router-dom';
import ROUTES from '../../../routes';
import Spinner from '../Utils/Spinner';
import EmptyMessage from '../Utils/EmptyMessage';
import SETTINGS from '../../../settings.json';
import ImageCard from '../Utils/ImageCard';
import PropType from 'prop-types';
import ImageCardGroup from '../Utils/ImageCardGroup';
import IconPerspective from '../../../assets/images/icons/icon-perspective.png';

const Articles = ({topic}) => {
  const articles = topic.articles;
  const [allArticlesVisible, toggleShowAllArticles] = useState(false);

  const renderSingleArticle = article => <div
    className='cell'
    key={article.id}>
    <ImageCard
      action='Explore Now'
      description={article.description}
      image={article.image}
      path={generatePath(ROUTES.ARTICLE, {url: article.url})}
      title={article.title}/>
  </div>;

  const renderArticles = () => {
    if (articles === null) {
      return <header><Spinner/></header>;
    }
    else if (articles.length === 0) {
      return <EmptyMessage
        alignCenter
        message='There are no articles to show.'/>;
    }

    return <div>
      <ImageCardGroup className='show-for-medium'>
        {articles.map((article, index) =>
          index < SETTINGS.NUM_ITEMS_VISIBLE_BY_DEFAULT || allArticlesVisible
            ? renderSingleArticle(article) : null)}
      </ImageCardGroup>
      <ImageCardGroup className='hide-for-medium'>
        {articles.map(article => renderSingleArticle(article))}
      </ImageCardGroup>
    </div>;
  };

  return <div id='articles'>
    <div className='grid-container'>
      <div className='grid-x align-center-middle padding-vertical-2 grid-margin-x grid-margin-y'>
        <div className='shrink cell auto'>
          <img
            alt='notepad'
            className='cell small-6 medium-2'
            src={IconPerspective}/>
        </div>
        <div className='cell medium-5 text-center medium-text-left'>
          <h2>Can You Tell Me More?</h2>
          {topic.next_level_article_resource_description}
        </div>
      </div>
      {renderArticles()}
    </div>
    {articles &&
          articles.length > SETTINGS.NUM_ITEMS_VISIBLE_BY_DEFAULT && !allArticlesVisible &&
          <div
            className='text-center show-for-medium'
            onClick={() => toggleShowAllArticles(!allArticlesVisible)}>
            <button
              className='primary large font-bold button hollow margin-top-2 rounded text-uppercase'
              type='button'
            >
              Load more articles
            </button>
          </div>}
  </div>;
};

Articles.propTypes = {
  topic: PropType.object
};

export default Articles;
