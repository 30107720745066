import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import Showdown from 'showdown';
import NetworkImage from '../../components/NetworkImage';
import {getArticleByURL} from '../../../redux/actions/articles';
import Spinner from '../Utils/Spinner';
import ApiError from '../ApiError';
import MetaHemlet from '../../components/MetaHemlet';

const Article = () => {
  const {url} = useParams();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const [error, setError] = useState(null);
  const converter = new Showdown.Converter();
  const [article, setArticle] = useState(null);

  useEffect(() => {
    dispatch(getArticleByURL(url))
      .then(action => {
        if (action.error) {
          setError(action.error);
        }
        else {
          setArticle(action.payload.data);
        }
      });
  }, [dispatch, url]);

  const articleContent = () => <>
    {/* eslint-disable-next-line */}
    <div dangerouslySetInnerHTML={{__html: converter.makeHtml(article.content)}}/>
  </>;

  if (error) {
    return <ApiError error={error}/>;
  }
  else if (article === null) {
    return <header><Spinner/></header>;
  }

  return (
    <>
      <MetaHemlet
        description={article.description}
        title={`Articles - ${article.title}`}
      />
      <NetworkImage
        format='large'
        header
        image={article.image}>
        <div className='h1'>{article.title}</div>
      </NetworkImage>
      <section className='padding-0 article'>
        <div className='grid-container show-for-medium'>
          <div className='grid-x align-stretch align-center margin-0'>
            <div className={`cell medium-8 ${article.sidebar ? 'large-6' : 'large-8'} padding-1`}>
              {articleContent()}
            </div>
            {
              article.sidebar &&
                <div className='cell medium-4 large-3 padding-1 sidebar'>
                  {/* eslint-disable-next-line */}
                  <div dangerouslySetInnerHTML={{__html: converter.makeHtml(article.sidebar)}}/>
                </div>
            }
          </div>
        </div>
        <div className='grid-container hide-for-medium'>
          <ul className='tabs'>
            <li className={`tabs-title ${activeTab === 0 ? 'is-active' : ''}`}>
              <button
                onClick={() => setActiveTab(0)}
                type='button'
              >Article</button>
            </li>
            { article.sidebar &&
                <li className={`tabs-title ${activeTab === 1 ? 'is-active' : ''}`}>
                  <button
                    onClick={() => setActiveTab(1)}
                    type='button'
                  >Did You Know...?</button>
                </li>
            }
          </ul>
          <div className='tabs-content'>
            <div className={`tabs-panel ${activeTab === 0 ? 'is-active' : ''}`}>
              {articleContent()}
            </div>
            {article.sidebar &&
                <div className={`tabs-panel ${activeTab === 1 ? 'is-active' : ''}`}>
                  {/* eslint-disable-next-line */}
                  <div dangerouslySetInnerHTML={{__html: converter.makeHtml(article.sidebar)}}/>
                </div>
            }
          </div>
        </div>
      </section>
    </>
  );
};

export default Article;
