import {SELECTED_ANSWERS} from '../constants';

export const setAnswer = (questionId, answerScore) => ({
  type: SELECTED_ANSWERS.SET_ANSWER,
  questionId,
  answerScore
});

export const clearAnswers = () => ({
  type: SELECTED_ANSWERS.CLEAR_ANSWERS
});
