import React from 'react';
import PropTypes from 'prop-types';

const EmptyMessage = ({message, alignCenter}) => {
  if (alignCenter) {
    return <div className='grid-y height-100 align-center'>
      <div className='cell'>
        <div className='primary callout margin-0'>
          {message}
        </div>
      </div>
    </div>;
  }

  return <div className='primary callout'>{message}</div>;
};

EmptyMessage.defaultProps = {
  alignCenter: false
};

EmptyMessage.propTypes = {
  alignCenter: PropTypes.bool,
  message: PropTypes.string.isRequired
};

export default EmptyMessage;
