import React from 'react';
import PropTypes from 'prop-types';

const ImageCardGroup = ({children, className}) => {
  const defaultclassName = 'grid-x grid-margin-x grid-margin-y small-up-1 medium-up-2 collapse';

  const classNameForLarge = () => {
    if (children.length % 2 === 0) {
      return 'large-up-2';
    }
    else if (children.length === 1) {
      return 'large-up-2 align-center';
    }

    return 'large-up-3';
  };

  return !children || children.length === 0 ? null
    : <div className={`${defaultclassName} ${classNameForLarge()} ${className}`.trim()}>
      {children}
    </div>;
};

ImageCardGroup.defaultProps = {
  className: ''
};

ImageCardGroup.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string
};

export default ImageCardGroup;
