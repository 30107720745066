import React from 'react';
import PropTypes from 'prop-types';

const ErrorScreen = ({error}) => <>
  <header>
    <div className='h1'>{error.status || (error.response && `${error.response.data} (${error.response.status})`)}</div>
  </header>
  <section>
    <div className='grid-container'>
      <h1>{error.data || error.message}</h1>
      <div className='lead'>Sorry, the page you are looking for could not be loaded.</div>
    </div>
  </section>
</>;

ErrorScreen.propTypes = {
  error: PropTypes.shape({
    data: PropTypes.string,
    message: PropTypes.string,
    response: PropTypes.shape({
      data: PropTypes.string,
      status: PropTypes.number
    }),
    status: PropTypes.number.isRequired
  }).isRequired
};

export default ErrorScreen;
