import ROUTES from '../../routes';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from 'react';
import {getTopics} from '../../redux/actions/topics';
import {generatePath} from 'react-router-dom';

const MENU = () => {
  const dispatch = useDispatch();
  const selectedTopic = useSelector(state => state.preferences.currentTopic);
  const topics = useSelector(state => state.topics);
  const topicMenu = topics ? topics
    .filter(topic => !selectedTopic || (selectedTopic && selectedTopic.url !== topic.url))
    .map(topic => ({path: generatePath(ROUTES.TOPIC, {url: topic.url}), title: topic.title}))
    : [];
  const mobileTopicMenu = topics ? topics
    .map(topic => ({path: generatePath(ROUTES.TOPIC, {url: topic.url}), title: topic.title}))
    : [];

  useEffect(() => {
    dispatch(getTopics());
  }, [dispatch]);

  return [
    {
      path: ROUTES.ROOT,
      title: 'Home'
    },
    {
      path: selectedTopic ? generatePath(ROUTES.TOPIC, {url: selectedTopic.url}) : `${ROUTES.ROOT}#topics`,
      title: selectedTopic ? selectedTopic.title : 'Life Experiences',
      topicMenu,
      mobileTopicMenu
    },
    {
      path: ROUTES.URGENT_RESOURCES,
      title: 'Crisis Line'
    },
    {
      path: ROUTES.ABOUT,
      title: 'About Hope'
    },
    {
      path: ROUTES.RESEARCH,
      title: 'Research'
    }
  ];
};

export default MENU;
