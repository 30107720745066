import React from 'react';
import Logo from '../../../assets/images/logo-dark.svg';
import {Link} from 'react-router-dom';
import ROUTES from '../../../routes';
import {HashLink} from 'react-router-hash-link';

const Index = () =>
  <footer className='text-center'>
    <p><img
      alt='logo'
      src={Logo}
      width={60}/>
    </p>
    <div className='grid-container'>
      <div className='grid-x align-center'>
        <div className='large-8 cell'>
          <ul className='menu align-center'>
            <li><Link to={ROUTES.ROOT}>Home</Link></li>
            <li><Link to={ROUTES.PRIVACY_POLICY}>Privacy Policy</Link></li>
            <li><Link to={ROUTES.DISCLAIMER}>Disclaimer</Link></li>
            <li><Link to={ROUTES.URGENT_RESOURCES}>Crisis Line</Link></li>
            <li><HashLink to={ROUTES.ABOUT}>About HOPE</HashLink></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>;

export default Index;
