import ReactGA from 'react-ga4';

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY, {
  debug: false,
  titleCase: false,
  anonymizeIp: true,
  testMode: process.env.NODE_ENV === 'test',
  gaOptions: {
    cookieFlags: 'SameSite=None;Secure'
  }
});

const trackEvent = action => {
  ReactGA.event({category: 'Redux Action', action}); // Track event to google analytics
};

/* eslint no-unused-vars: "off"*/
const middleware = store => next => action => {
  const {type} = action;
  ReactGA.set({anonymizeIp: true});

  if (!type.includes('persist/')) {
    trackEvent(type);
  }

  return next(action);
};

export default middleware;
