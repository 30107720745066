import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import MENU from '../utils/menu';
import {Link} from 'react-router-dom';

const OffCanvasMenu = ({currentPath, isShown, toggleOffCanvasMenu}) =>
  <div className={`off-canvas position-top ${isShown ? 'is-open' : 'invisible'}`}>
    <div className='off-canvas-content'>
      <button
        className='close-button'
        onClick={() => toggleOffCanvasMenu(!isShown)}
        type='button'>
        <span aria-hidden='true'>&times;</span>
      </button>
      <ul className='vertical menu display-inline-block width-100'>
        {/* eslint-disable-next-line new-cap */}
        {MENU().map(menuItem => {
          if (menuItem.mobileTopicMenu) {
            return <Fragment key={menuItem.title}>
              <li className='divider'/>
              {menuItem.mobileTopicMenu.map(item => <li key={item.path}><Link
                className={`padding-horizontal-2 ${currentPath === item.path ? 'active' : ''}`}
                to={item.path}>{item.title}</Link></li>)}
              <li className='divider'/>
            </Fragment>;
          }

          return <li key={menuItem.path}><Link
            className={`padding-horizontal-2 ${currentPath === menuItem.path ? 'active' : ''}`}
            to={menuItem.path}>{menuItem.title}</Link></li>;
        })}
      </ul>

    </div>

  </div>;

OffCanvasMenu.propTypes = {
  currentPath: PropTypes.string.isRequired,
  isShown: PropTypes.bool.isRequired,
  toggleOffCanvasMenu: PropTypes.func.isRequired
};

export default OffCanvasMenu;
