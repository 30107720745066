import React from 'react';

const Spinner = () =>
  <div className='grid-y height-100 align-center'>
    <div className='shrink cell'>
      <div className='grid-x align-center'>
        <div className='shrink cell'>
          <div className='lds-roller'>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
          </div>
        </div>
      </div>
    </div>
  </div>;

export default Spinner;
