import React from 'react';

const NoRoute = () => <>
  <header>
    <div className='h1'>Page Not Found (404)</div>
  </header>
  <section>
    <div className='grid-container'>
      <h1>Page Not Found (404)</h1>
      <div className='lead'>Sorry, the page you are looking for cannot be found.</div>

    </div>
  </section>
</>;

export default NoRoute;
