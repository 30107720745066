import React, {useEffect, useState} from 'react';
import Screeners from './Screeners';
import Courses from './Courses';
import Articles from './Articles';
import {useDispatch} from 'react-redux';
import {getTopicByURL} from '../../../redux/actions/topics';
import NoRoute from '../404';
import Spinner from '../Utils/Spinner';
import {useParams} from 'react-router-dom';
import NetworkImage from '../../components/NetworkImage';
import Resources from './Resources';
import {HashLink} from 'react-router-hash-link';
import _ from 'lodash';
import MetaHemlet from '../../components/MetaHemlet';

const EducationScreen = function() {
  const {url} = useParams();
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [topic, setTopic] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const hash = window.location.hash;

  const sortCoursesAndScreenersByIndex = topicObj => {
    if (topicObj.courses) {
      topicObj.courses = _.sortBy(topicObj.courses, 'index');
    }

    if (topicObj.screeners) {
      topicObj.screeners = _.sortBy(topicObj.screeners, 'index');
    }

    if (topicObj.articles) {
      topicObj.articles = _.sortBy(topicObj.articles, 'index');
    }

    return topicObj;
  };

  useEffect(() => {
    if (hash) {
      const tabs = {
        '#resources': {
          tabNumber: 0,
          title: `${topic?.title || ''} Resources`.trim(),
          description: ''
        },
        '#screeners': {
          tabNumber: 1,
          title: `${topic?.title || ''} Screeners`.trim(),
          description: ''
        },
        '#courses': {
          tabNumber: 2,
          title: `${topic?.title || ''} Courses`.trim(),
          description: ''
        },
        '#articles': {
          tabNumber: 3,
          title: `${topic?.title || ''} Articles`.trim(),
          description: ''
        }
      };
      const newActiveTab = tabs[hash] || tabs[Object.keys(tabs)[0]];
      setActiveTab(newActiveTab.tabNumber);
      document.title = `${newActiveTab.title} - Hope Digital Platform`;
      document.getElementsByTagName('meta').description.content = newActiveTab.description;
    }
    else {
      setActiveTab(0);
    }
  }, [hash, topic]);

  useEffect(() => {
    dispatch(getTopicByURL(url))
      .then(action => {
        if (action.error) {
          setError(action.error);
        }
        else {
          setTopic(sortCoursesAndScreenersByIndex(action.payload.data));
          setError(null);
        }
      })
      .catch(err => setError(err.getMessage()));
  }, [dispatch, url]);

  if (error) {
    return <NoRoute/>;
  }

  if (topic === null) {
    return <header><Spinner/></header>;
  }

  return <>
    <MetaHemlet
      description={topic.description}
      title={`Topic - ${topic.title}`}
    />
    <NetworkImage
      className='position-relative'
      format='large'
      header
      image={topic.image}>
      <div className='grid-container width-100 text-left teal-color'>
        <div className='text-uppercase'>Chosen topic</div>
        <h1 className='padding-horizontal-1'>{topic.title}</h1>
      </div>
      <div className='position-absolute resource-tabs text-uppercase'>
        <ul className='tabs'>
          <li className={`tabs-title ${activeTab === 0 ? 'is-active' : ''}`}>
            <HashLink
              aria-selected={activeTab === 0 ? 'true' : ''}
              onClick={() => {
                setActiveTab(0);
              }}
              scroll={() => null}
              to='#resources'>Main</HashLink>
          </li>
          <li className={`tabs-title ${activeTab === 1 ? 'is-active' : ''}`}>
            <HashLink
              aria-selected={activeTab === 1 ? 'true' : ''}
              onClick={() => setActiveTab(1)}
              scroll={() => null}
              to='#screeners'>Is This Me?</HashLink>
          </li>
          <li className={`tabs-title ${activeTab === 2 ? 'is-active' : ''}`}>
            <HashLink
              aria-selected={activeTab === 2 ? 'true' : ''}
              onClick={() => setActiveTab(2)}
              scroll={() => null}
              to='#courses'>What Can I Do?</HashLink>
          </li>
          <li className={`tabs-title ${activeTab === 3 ? 'is-active' : ''}`}>
            <HashLink
              aria-selected={activeTab === 3 ? 'true' : ''}
              onClick={() => setActiveTab(3)}
              scroll={() => null}
              to='#articles'>Can You Tell Me More?</HashLink>
          </li>
        </ul>
      </div>
    </NetworkImage>
    <section
      className={`tabs-panel ${activeTab === 0 ? 'is-active' : ''}`}>
      <Resources
        setActiveTab={setActiveTab}
        topic={topic}/>
    </section>
    <section
      className={`dark tabs-panel ${activeTab === 1 ? 'is-active' : ''}`}>
      <Screeners topic={topic}/>
    </section>
    <section
      className={`dark tabs-panel ${activeTab === 2 ? 'is-active' : ''}`}>
      <Courses topic={topic}/>
    </section>
    <section
      className={`dark tabs-panel ${activeTab === 3 ? 'is-active' : ''}`}>
      <Articles topic={topic}/>
    </section>
  </>;
};

export default EducationScreen;
