import React from 'react';
import titleToUrl from '../Utils/titleToUrl';
import {push} from '@lagunovsky/redux-react-router';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {generatePath} from 'react-router-dom';
import ROUTES from '../../../routes';

const PreviousPageButton = ({section, course, page}) => {
  const dispatch = useDispatch();

  const previousPage = () => {
    const pIndex = section.pages.findIndex(p => p.id === page.id);

    if (pIndex === 0) {
      // get prev section
      const sectionIndex = course.sections.findIndex(s => s.id === section.id);
      const newSection = course.sections[sectionIndex - 1];
      dispatch(push(generatePath(ROUTES.COURSE_DETAILS,
        {url: course.url,
          sectionSlug: titleToUrl(newSection.title),
          pageIndex: newSection.pages.length})));
    }
    else {
      dispatch(push(generatePath(ROUTES.COURSE_DETAILS,
        {url: course.url,
          sectionSlug: titleToUrl(section.title),
          pageIndex: pIndex})));
    }
  };

  return <div>
    <button
      className='button'
      onClick={previousPage}
      type='button'>Previous Page</button>
  </div>;
};

PreviousPageButton.propTypes = {
  course: PropTypes.object,
  page: PropTypes.object,
  section: PropTypes.object
};

export default PreviousPageButton;
