import _ from 'lodash';

const sortSitePartners = sitePartners => {
  if (sitePartners) {
    return _.sortBy(sitePartners, 'index');
  }

  return sitePartners;
};

export default sortSitePartners;
