import React from 'react';
import titleToUrl from '../Utils/titleToUrl';
import {push} from '@lagunovsky/redux-react-router';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {generatePath} from 'react-router-dom';
import ROUTES from '../../../routes';

const NextPageButton = ({section, course, page}) => {
  const dispatch = useDispatch();

  const nextPage = () => {
    // find page in current section
    const pIndex = section.pages.findIndex(p => p.id === page.id);

    if (pIndex === section.pages.length - 1) {
      // get next section
      const sectionIndex = course.sections.findIndex(s => s.id === section.id);
      const newSection = course.sections[sectionIndex + 1];
      dispatch(push(generatePath(ROUTES.COURSE_DETAILS,
        {url: course.url, sectionSlug: titleToUrl(newSection.title), pageIndex: 1})));
    }
    else {
      dispatch(push(generatePath(ROUTES.COURSE_DETAILS,
        {url: course.url, sectionSlug: titleToUrl(section.title), pageIndex: pIndex + 2})));
    }
  };

  return <button
    className='button'
    onClick={nextPage}
    type='button'>Next Page</button>;
};

NextPageButton.propTypes = {
  course: PropTypes.object,
  page: PropTypes.object,
  section: PropTypes.object
};

export default NextPageButton;
