import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {generatePath, useParams} from 'react-router-dom';
import NoRoute from '../404';
import Showdown from 'showdown';
import {push} from '@lagunovsky/redux-react-router';
import {clearAnswers, setAnswer} from '../../../redux/actions/selectedAnswers';
import Background from '../../../assets/images/headers/home.jpg';
import {getScreenerByURL} from '../../../redux/actions/screeners';
import {setScore} from '../../../redux/actions/score';
import Spinner from '../Utils/Spinner';
import ApiError from '../ApiError';
import ROUTES from '../../../routes';
import MetaHemlet from '../../components/MetaHemlet';
import _ from 'lodash';

const Screener = function() { // eslint-disable-line max-statements
  const {url, questionIndex} = useParams();
  const dispatch = useDispatch();
  const [screener, setScreener] = useState(null);
  const [question, setQuestion] = useState(null);
  const [error, setError] = useState(null);
  const [firstQuestionId, setFirstQuestionId] = useState('');
  const [lastQuestionId, setLastQuestionId] = useState('');
  const [currentAnswer, setCurrentAnswer] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const selectedAnswers = useSelector(state => state.selectedAnswers);
  const [screenerStarted, setScreenerStarted] = useState(false);
  const selectedTopic = useSelector(state => state.preferences.currentTopic);

  const converter = new Showdown.Converter();

  const setScreenerParams = screenerData => {
    const firstQuestion = screenerData.questions[0];
    const lastQuestion = screenerData.questions[screenerData.questions.length - 1];
    setFirstQuestionId(firstQuestion.id);
    setLastQuestionId(lastQuestion.id);
    setQuestion(firstQuestion);
    setScreener(screenerData);
  };

  const sortSccreenerQuestions = screenerObj => {
    if (screenerObj.questions) {
      screenerObj.questions = _.sortBy(screenerObj.questions, 'index');
    }

    return screenerObj;
  };

  useEffect(() => {
    dispatch(getScreenerByURL(url))
      .then(action => {
        if (action.error) {
          setError(action.error);
        }
        else {
          setScreenerParams(sortSccreenerQuestions(action.payload.data));
        }
      });

    return () => {
      dispatch(clearAnswers());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCurrentAnswer(null);
    setShowMessage(false);

    if (screener) {
      const q = questionIndex ? screener.questions[questionIndex - 1] : screener.questions[0];

      if (q) {
        setQuestion(q);
        setCurrentAnswer(selectedAnswers[q.id] || null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screener, url, questionIndex]);

  if (error) {
    return <NoRoute error={error}/>;
  }

  if (screener === null) {
    return <header><Spinner/></header>;
  }

  const changeAnswer = answer => {
    setCurrentAnswer(answer);
    dispatch(setAnswer(question.id, answer.score));
    setShowMessage(false);
  };

  const goToResults = () => {
    let score = 0;
    screener.questions.forEach(q => {
      const answer = selectedAnswers[q.id];

      if (answer && typeof answer === 'number') {
        score += answer;
      }
    });
    dispatch(setScore(score));
    dispatch(push(`/screeners/${screener.url}/results`));
  };

  const goToIntro = () => {
    setScreenerStarted(false);
    dispatch(push(`/screeners/${screener.url}`));
  };

  const nextQuestion = () => {
    if (!screenerStarted) {
      setScreenerStarted(true);
      dispatch(push(`/screeners/${screener.url}/1`));
    }
    else if (currentAnswer === null) {
      setShowMessage(true);
    }
    else if (question.branching) {
      if (currentAnswer === 'Yes' && question.if_answer_yes !== null) {
        const qIndex = screener.questions.findIndex(q => q.id === question.if_answer_yes);
        dispatch(push(`/screeners/${screener.url}/${qIndex + 1}`));
      }
      else if (currentAnswer === 'No' && question.if_answer_no !== null) {
        const qIndex = screener.questions.findIndex(q => q.id === question.if_answer_no);
        dispatch(push(`/screeners/${screener.url}/${qIndex + 1}`));
      }
      else {
        goToResults();
      }
    }
    else {
      const qIndex = screener.questions.findIndex(q => q.id === question.id);
      dispatch(push(`/screeners/${screener.url}/${qIndex + 2}`));
    }
  };

  const previousQuestion = () => {
    const yesQuestionIndex = screener.questions.findIndex(q => q.if_answer_yes === question.id);

    if (yesQuestionIndex !== -1) {
      const yesQuestionId = screener.questions[yesQuestionIndex].id;

      if (selectedAnswers[yesQuestionId] === 'Yes') {
        dispatch(push(`/screeners/${screener.url}/${yesQuestionIndex + 1}`));
        return;
      }
    }

    const noQuestionIndex = screener.questions.findIndex(q => q.if_answer_no === question.id);

    if (noQuestionIndex !== -1) {
      const noQuestionId = screener.questions[noQuestionIndex].id;

      if (selectedAnswers[noQuestionId] === 'No') {
        dispatch(push(`/screeners/${screener.url}/${noQuestionIndex + 1}`));
        return;
      }
    }

    const qIndex = screener.questions.findIndex(q => q.id === question.id);
    dispatch(push(`/screeners/${screener.url}/${qIndex}`));
  };

  const cancelScreener = () => {
    dispatch(push(generatePath(ROUTES.TOPIC, {url: selectedTopic.url})));
  };

  const answers = () => {
    if (question.branching) {
      return ['Yes', 'No'].map(answer =>
        <div
          className='grid-x align-middle'
          key={answer}
          onClick={() => changeAnswer(answer)}
        >
          <div className='shrink cell'>
            <input
              checked={currentAnswer === answer} // Check if currentAnswer matches the selected answer
              id={answer}
              name={answer}
              onChange={() => changeAnswer(answer)}
              required
              type='radio'
              value={answer}
            />
          </div>
          <div className='auto cell'>
            <label
              className='lead margin-bottom-1 margin-left-1'
              htmlFor={answer}>{answer}</label>
          </div>
        </div>);
    }

    return question.answers.map(answer =>
      <div
        className='grid-x align-middle'
        key={answer.id}
        onClick={() => changeAnswer(answer)}
      >
        <div className='shrink cell'>
          <input
            checked={currentAnswer?.id === answer.id} // Check if currentAnswer matches the selected answer ID
            id={answer.id}
            name={answer.text}
            onChange={() => changeAnswer(answer)}
            required
            type='radio'
            value={answer.score}
          />
        </div>
        <div className='auto cell'>
          <label
            className='lead margin-bottom-1 margin-left-1'
            htmlFor={answer.id}>{answer.text}</label>
        </div>
      </div>);
  };

  if (error) {
    return <ApiError error={error}/>;
  }
  else if (!screener) {
    return <header><Spinner/></header>;
  }

  return <>
    <MetaHemlet
      description={screener.description}
      title={`${screener.title} ${screenerStarted ? `- Question ${questionIndex}` : ''}`}
    />
    <section
      className='height-100'
      style={{backgroundImage: `url(${Background})`}}>
      <div className='grid-container'>
        <div className='grid-x align-center'>
          <div className='large-8 cell'>
            <div className='screener'>
              <div className='grid-x grid-margin-x align-middle margin-bottom-1'>
                <div className='shrink cell'>
                  <div className='time-estimate margin-bottom-1'>
                    <span className='h2 margin-0'>{screener.minutes}</span>min
                  </div>
                </div>
                <div className='auto cell'>
                  <h1>{screener.title}</h1>
                  {screenerStarted && <p className='lead'>{screener.description}</p>}
                </div>
              </div>
              {screenerStarted === false
                ? <div className='card' >
                  <div className='card-section'>
                    {/* eslint-disable-next-line */}
                    <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(screener.introduction) }} />                    <div/>
                  </div>
                  <div className='card-divider display-block'>
                    <div className='grid-x grid-margin-x align-spaced'>
                      <div className='auto medium-4 cell'>
                        <button
                          className='expanded large button margin-0'
                          onClick={cancelScreener}
                          type='button'>Cancel</button></div>
                      <div className='auto medium-4 cell'>
                        <button
                          className='expanded large button margin-0'
                          onClick={nextQuestion}
                          type='button'>Start</button></div>
                    </div>
                  </div>
                </div>
                : <div className='card' >
                  {screenerStarted
                    ? <div
                      className='progress margin-bottom-0'
                      role='progressbar'>
                      <div
                        className='progress-meter'
                        style={{width: `${(questionIndex - 1.0) / screener.questions.length * 100}%`}}/>
                    </div> : null}
                  <div className='card-divider'>
                    <div className='lead'>{question.text}</div>
                  </div>
                  <div className='card-section'>
                    {answers()}
                    {showMessage && <div className='error-message'>Please select an answer to the question.</div>}
                    <div/>
                  </div>
                  <div className='card-divider display-block'>
                    <div className='grid-x grid-margin-x align-spaced'>
                      <div className='auto medium-4 cell'>
                        <button
                          className='expanded large button margin-0'
                          onClick={question.id === firstQuestionId ? goToIntro : previousQuestion}
                          type='button'>Previous</button></div>
                      <div className='auto medium-4 cell'>
                        <button
                          className='expanded large button margin-0'
                          onClick={question.id === lastQuestionId ? goToResults : nextQuestion}
                          type='button'>Next</button></div>
                    </div>
                  </div>
                </div>}
            </div>
          </div>
        </div>
      </div>
    </section>
  </>;
};

export default Screener;
