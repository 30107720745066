import React from 'react';
import {Helmet} from 'react-helmet-async';
import PropTypes from 'prop-types';

const MetaHemlet = ({title, description}) =>
  <Helmet prioritizeSeoTags>
    <link
      href={window.location.href}
      rel='Canonical'/>
    <title>{`${title} | Hope Platform`}</title>
    <meta
      content={description}
      name='description'/>
    <meta
      content={window.location.href}
      property='og:url'/>
    <meta
      content={`${title} | Hope Platform`}
      property='og:title'/>
    <meta
      content={description}
      property='og:description'/>
    <meta
      content={window.location.href}
      property='twitter:url'/>
    <meta
      content={`${title} | Hope Platform`}
      property='twitter:title'/>
    <meta
      content={description}
      property='twitter:description'/>
  </Helmet>;

export default MetaHemlet;

MetaHemlet.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string
};
