import React, {useState} from 'react';
import {generatePath} from 'react-router-dom';
import Spinner from '../Utils/Spinner';
import EmptyMessage from '../Utils/EmptyMessage';
import SETTINGS from '../../../settings.json';
import ROUTES from '../../../routes';
import ImageCard from '../Utils/ImageCard';
import PropType from 'prop-types';
import ImageCardGroup from '../Utils/ImageCardGroup';
import IconLearn from '../../../assets/images/icons/icon-learn.png';

const Courses = ({topic}) => {
  const courses = topic.courses;
  const [allCoursesVisible, toggleShowAllCourses] = useState(false);

  const renderSingleCourse = course => <div
    className='cell'
    key={course.id}>
    <ImageCard
      action='Explore Now'
      description={course.description}
      image={course.image}
      path={generatePath(ROUTES.COURSE_DETAILS_INTRO, {url: course.url})}
      title={course.title}/>
  </div>;

  const renderCourses = () => {
    if (courses === null) {
      return <image><Spinner/></image>;
    }
    else if (courses.length === 0) {
      return <EmptyMessage
        alignCenter
        message='There are no courses to show.'/>;
    }

    return <div>
      <ImageCardGroup className='show-for-medium'>
        {courses.map((course, index) =>
          index < SETTINGS.NUM_ITEMS_VISIBLE_BY_DEFAULT || allCoursesVisible
            ? renderSingleCourse(course)
            : null)}
      </ImageCardGroup>
      <ImageCardGroup className='hide-for-medium'>
        {courses.map(course => renderSingleCourse(course))}
      </ImageCardGroup>
    </div>;
  };

  return <div id='courses'>
    <div className='grid-container'>
      <div className='grid-x align-center-middle padding-vertical-2 grid-margin-x grid-margin-y'>
        <div className='shrink cell auto'>
          <img
            alt='notepad'
            className='cell medium-2'
            src={IconLearn}/>
        </div>
        <div className='cell medium-5 text-center medium-text-left'>
          <h2>What Can I Do?</h2>
          {topic.next_level_course_resource_description}
        </div>
      </div>
      {renderCourses()}
    </div>
    {courses &&
      courses.length > SETTINGS.NUM_ITEMS_VISIBLE_BY_DEFAULT && !allCoursesVisible &&
      <div
        className='text-center show-for-medium'
        onClick={() => toggleShowAllCourses(!allCoursesVisible)}>
        <button
          className='primary large font-bold button hollow margin-top-2 rounded text-uppercase'
          type='button'
        >
          Load more courses
        </button>
      </div>}
  </div>;
};

Courses.propTypes = {
  topic: PropType.object
};

export default Courses;
