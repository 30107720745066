import {SCREENERS} from '../constants';

export const getScreenerByURL = url => ({
  type: SCREENERS.GET_SCREENER,
  payload: {
    request: {
      url: `/api/screeners/${url}`,
      method: 'GET'
    }
  }
});
