import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import configureStore, {history} from './redux/store';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import * as serviceWorker from './serviceWorker';
import {ReduxRouter} from '@lagunovsky/redux-react-router';
import {Provider as RollbarProvider, ErrorBoundary} from '@rollbar/react';
import './assets/stylesheets/application.scss';
import {HelmetProvider} from 'react-helmet-async';
import Header from './../src/app/containers/Header';
import Footer from './../src/app/containers/Footer';

const {store, persistor} = configureStore();

const rollbarConfig = {
  enabled: process.env.REACT_APP_ENVIRONMENT !== 'development',
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureIp: false,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.REACT_APP_ENVIRONMENT
  }
};

const container = document.getElementById('root');
const root = createRoot(container);

root.render(<React.StrictMode>
  <RollbarProvider config={rollbarConfig}>
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate
          loading={null}
          persistor={persistor}
        >
          <HelmetProvider>
            <ReduxRouter history={history}>
              <Header/>
              <App/>
              <Footer/>
            </ReduxRouter>
          </HelmetProvider>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  </RollbarProvider>
</React.StrictMode>);

/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
