import {SCORE} from '../constants';

const initialState = '';

export default function score(state = initialState, action) {
  switch (action.type) {
  case SCORE.SET_SCORE: {
    return action.score;
  }

  case SCORE.CLEAR_SCORE:
    return initialState;
  default:
    return state;
  }
}
