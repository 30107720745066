import {SELECTED_ANSWERS} from '../constants';

const initialState = {};

export default function selectedAnswers(state = initialState, action) {
  switch (action.type) {
  case SELECTED_ANSWERS.SET_ANSWER: {
    const newState = {
      ...state
    };
    newState[action.questionId] = action.answerScore;
    return newState;
  }

  case SELECTED_ANSWERS.CLEAR_ANSWERS:
    return initialState;
  default:
    return state;
  }
}
