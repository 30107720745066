import React, {useEffect, useState} from 'react';
import Logo from '../../../assets/images/logo-light.svg';
import {Link, useLocation} from 'react-router-dom';
import ROUTES from '../../../routes';
import MENU from '../../utils/menu';
import OffCanvasMenu from '../../components/OffCanvasMenu';
import $ from 'jquery';

const Index = () => {
  const BACKGROUND_CHANGE_SCROLL_OFFSET = 250;
  const location = useLocation();
  const [className, setClassname] = useState(null);
  const [isOffCanvasMenuShown, toggleOffCanvasMenu] = useState(false);

  useEffect(() => {
    toggleOffCanvasMenu(false);
  }, [location]);

  useEffect(() => {
    const updateClassname = () => {
      if (document.documentElement.scrollTop >= BACKGROUND_CHANGE_SCROLL_OFFSET) {
        setClassname('primary');
      }
      else {
        setClassname(null);
      }
    };

    updateClassname();
    document.addEventListener('wheel', updateClassname);

    return () => {
      document.removeEventListener('wheel', updateClassname);
    };
  }, []);

  useEffect(() => {
    const $mainContent = $('#main');

    if (isOffCanvasMenuShown && !$mainContent.hasClass('disable-scrolling')) {
      $mainContent.addClass('disable-scrolling');
    }
    else if (!isOffCanvasMenuShown) {
      $mainContent.removeClass('disable-scrolling');
    }
  }, [isOffCanvasMenuShown]);

  return <div>
    <OffCanvasMenu
      currentPath={location.pathname}
      isShown={isOffCanvasMenuShown}
      toggleOffCanvasMenu={toggleOffCanvasMenu}/>
    <nav className={className}>
      <div className='grid-container padding-0'>
        <div className='title-bar'>
          <div className='title-bar-left'>
            <ul
              className='dropdown menu height-100'>
              <li className='logo-wrapper'><Link
                className='logo'
                to={ROUTES.ROOT}><img
                  alt='logo'
                  src={Logo}/></Link></li>
              {
              // eslint-disable-next-line new-cap
                MENU().map(menuItem => <li
                  className={`show-for-large ${menuItem.topicMenu ? 'is-dropdown-submenu-parent opens-right' : ''}`}
                  key={menuItem.path}>
                  <Link
                    className={location.pathname === menuItem.path ? 'active' : ''}
                    to={menuItem.path}>{menuItem.title}</Link>
                  {menuItem.topicMenu ? <ul className='menu drop-down submenu is-dropdown-submenu first-sub vertical'>
                    {menuItem.topicMenu.map(item => <li key={item.title}>
                      <Link
                        to={item.path}>{item.title}</Link>
                    </li>)}
                  </ul> : null
                  }

                </li>)
              }
            </ul>
          </div>
          <div className='title-bar-right hide-for-large'>
            <button
              className='menu-icon'
              onClick={() => toggleOffCanvasMenu(!isOffCanvasMenuShown)}
              type='button'/>
          </div>
        </div>
      </div>
    </nav>
  </div>;
};

export default Index;
