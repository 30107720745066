export const ARTICLES = {
  GET_ARTICLES: 'GET_ARTICLES',
  GET_ARTICLES_SUCCESS: 'GET_ARTICLES_SUCCESS',
  GET_ARTICLES_FAIL: 'GET_ARTICLES_FAIL',
  GET_ARTICLE: 'GET_ARTICLE',
  GET_ARTICLE_SUCCESS: 'GET_ARTICLE_SUCCESS',
  GET_ARTICLE_FAIL: 'GET_ARTICLE_FAIL'
};

export const COURSES = {
  GET_COURSES: 'GET_COURSES',
  GET_COURSES_SUCCESS: 'GET_COURSES_SUCCESS',
  GET_COURSES_FAIL: 'GET_COURSES_FAIL',
  GET_COURSE: 'GET_COURSE',
  GET_COURSE_SUCCESS: 'GET_COURSE_SUCCESS',
  GET_COURSE_FAIL: 'GET_COURSE_FAIL'
};

export const SCREENERS = {
  GET_SCREENERS: 'GET_SCREENERS',
  GET_SCREENERS_SUCCESS: 'GET_SCREENERS_SUCCESS',
  GET_SCREENERS_FAIL: 'GET_SCREENERS_FAIL',
  GET_SCREENER: 'GET_SCREENER',
  GET_SCREENER_SUCCESS: 'GET_SCREENER_SUCCESS',
  GET_SCREENER_FAIL: 'GET_SCREENER_FAIL'
};

export const TOPICS = {
  GET_TOPICS: 'GET_TOPICS',
  GET_TOPICS_SUCCESS: 'GET_TOPICS_SUCCESS',
  GET_TOPICS_FAIL: 'GET_TOPICS_FAIL',
  GET_TOPIC: 'GET_TOPIC',
  GET_TOPIC_SUCCESS: 'GET_TOPIC_SUCCESS',
  GET_TOPIC_FAIL: 'GET_TOPIC_FAIL'
};

export const SITE_PARTNERS = {
  GET_SITE_PARTNERS: 'GET_SITE_PARTNERS',
  GET_SITE_PARTNERS_SUCCESS: 'GET_SITE_PARTNERS_SUCCESS',
  GET_SITE_PARTNERS_FAIL: 'GET_SITE_PARTNERS_FAIL'
};

export const SELECTED_ANSWERS = {
  SET_ANSWER: 'SET_ANSWER',
  CLEAR_ANSWERS: 'CLEAR_ANSWERS'
};

export const SCORE = {
  SET_SCORE: 'SET_SCORE',
  CLEAR_SCORE: 'CLEAR_SCORE'
};

export const URGENT_RESOURCES = {
  GET_URGENT_RESOURCES: 'GET_URGENT_RESOURCES',
  GET_URGENT_RESOURCES_SUCCESS: 'GET_URGENT_RESOURCES_SUCCESS',
  GET_URGENT_RESOURCES_FAIL: 'GET_URGENT_RESOURCES_FAIL'
};

export const DISCLAIMER = {
  GET_DISCLAIMER: 'GET_DISCLAIMER',
  GET_DISCLAIMER_SUCCESS: 'GET_DISCLAIMER_SUCCESS',
  GET_DISCLAIMER_FAIL: 'GET_DISCLAIMER_FAIL'
};

export const PRIVACY_POLICY = {
  GET_PRIVACY_POLICY: 'GET_PRIVACY_POLICY',
  GET_PRIVACY_POLICY_SUCCESS: 'GET_PRIVACY_POLICY_SUCCESS',
  GET_PRIVACY_POLICY_FAIL: 'GET_PRIVACY_POLICY_FAIL'
};

export const HOME_PAGE_VIDEOS = {
  GET_HOME_PAGE_VIDEOS: 'GET_HOME_PAGE_VIDEOS',
  GET_HOME_PAGE_VIDEOS_SUCCESS: 'GET_HOME_PAGE_VIDEOS_SUCCESS',
  GET_HOME_PAGE_VIDEOS_FAIL: 'GET_HOME_PAGE_VIDEOS_FAIL'
};

export const ABOUT = {
  GET_ABOUT: 'GET_ABOUT',
  GET_ABOUT_SUCCESS: 'GET_ABOUT_SUCCESS',
  GET_ABOUT_FAIL: 'GET_ABOUT_FAIL'
};

export const RESEARCH = {
  GET_RESEARCH: 'GET_RESEARCH',
  GET_RESEARCH_SUCCESS: 'GET_RESEARCH_SUCCESS',
  GET_RESEARCH_FAIL: 'GET_RESEARCH_FAIL'
};
