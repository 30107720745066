import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import NetworkImage from '../../components/NetworkImage';

const ImageCard = ({title, description, image, path, action, className}) => <NetworkImage
  className='height-100 shadow'
  format='medium'
  image={image}>
  <Link
    className='card margin-0 has-background-image height-100'
    to={path}>
    <div className={`card-section padding-2 ${className}`}>
      <h3 className='padding-vertical-2'>{title}</h3>
      <div>{description}</div>
    </div>
    <div className='card-divider padding-2'>
      <button
        className='success shadow text-uppercase font-bold button margin-bottom-0'
        type='button'>{action} &#10132;</button>
    </div>
  </Link>
</NetworkImage>;

ImageCard.propTypes = {
  action: PropTypes.string.isRequired,
  className: PropTypes.string,
  description: PropTypes.string.isRequired,
  image: PropTypes.object,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default ImageCard;
