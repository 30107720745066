import React from 'react';
import PropTypes from 'prop-types';
import {generatePath} from 'react-router-dom';
import ROUTES from '../../../routes';
import ImageCard from '../Utils/ImageCard';
import Spinner from '../Utils/Spinner';
import EmptyMessage from '../Utils/EmptyMessage';
import ImageCardGroup from '../Utils/ImageCardGroup';

const ReliefAreaCards = ({topics}) => {
  const renderTopics = () => {
    if (topics === null) {
      return <Spinner/>;
    }
    else if (topics.length === 0) {
      return <EmptyMessage
        alignCenter
        message='There are no topics to show.'/>;
    }

    return <ImageCardGroup>
      {topics.map(topic =>
        <div
          className='cell'
          key={topic.id}>
          <ImageCard
            action='Explore now'
            className='white-color'
            description={topic.description}
            image={topic.tile_image}
            path={generatePath(ROUTES.TOPIC, {url: topic.url})}
            title={topic.title}/>
        </div>)}
    </ImageCardGroup>;
  };

  return <div>
    <div className='grid-x align-center medium-up-2'>
      {renderTopics()}
    </div>
  </div>;
};

ReliefAreaCards.propTypes = {
  topics: PropTypes.array.isRequired
};

export default ReliefAreaCards;
