import {ARTICLES, PRIVACY_POLICY, URGENT_RESOURCES, ABOUT, DISCLAIMER, RESEARCH} from '../constants';

export const getArticleByURL = url => ({
  type: ARTICLES.GET_ARTICLE,
  payload: {
    request: {
      url: `/api/articles/${url}`,
      method: 'GET'
    }
  }
});

export const getPrivacyPolicy = () => ({
  type: PRIVACY_POLICY.GET_PRIVACY_POLICY,
  payload: {
    request: {
      url: '/api/privacy-policy',
      method: 'GET'
    }
  }
});

export const getDisclaimer = () => ({
  type: DISCLAIMER.GET_DISCLAIMER,
  payload: {
    request: {
      url: '/api/disclaimer',
      method: 'GET'
    }
  }
});

export const getUrgentResources = () => ({
  type: URGENT_RESOURCES.GET_URGENT_RESOURCES,
  payload: {
    request: {
      url: '/api/urgent-resource',
      method: 'GET'
    }
  }
});

export const getAboutData = () => ({
  type: ABOUT.GET_ABOUT,
  payload: {
    request: {
      url: 'api/about-hope',
      method: 'GET'
    }
  }
});

export const getResearch = () => ({
  type: RESEARCH.GET_RESEARCH,
  payload: {
    request: {
      url: '/api/research',
      method: 'GET'
    }
  }
});
