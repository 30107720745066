import {HOME_PAGE_VIDEOS} from '../constants';

export const getHomePageVideos = () => ({
  type: HOME_PAGE_VIDEOS.GET_HOME_PAGE_VIDEOS,
  payload: {
    request: {
      url: '/api/home-page-videos',
      method: 'GET'
    }
  }
});
