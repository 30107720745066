const ROUTES = {
  ABOUT: '/about',
  RESEARCH: '/research',
  ARTICLE: '/articles/:url',
  CONTACT: '/contact',
  COURSE_DETAILS: '/courses/:url/:sectionSlug/pages/:pageIndex',
  COURSE_DETAILS_INTRO: '/courses/:url',
  PRIVACY_POLICY: '/privacy-policy',
  ROOT: '/',
  SCREENER_DETAILS: '/screeners/:url/:questionIndex',
  SCREENER_DETAILS_INTRO: '/screeners/:url',
  SCREENER_RESULTS: '/screeners/:url/results',
  DISCLAIMER: '/disclaimer',
  TOPIC: '/topics/:url',
  URGENT_RESOURCES: '/urgent-resources'
};

export default ROUTES;
